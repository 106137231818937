'use strict'

const wixCodeLogLevel = {
  INFO: 'INFO',
  WARN: 'WARNING',
  ERROR: 'ERROR',
  LOG: 'LOG',
  VERBOSE: 'VERBOSE'
}

const siteMonitoringSeverity = {
  DEFAULT: 'DEFAULT',
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
}

const mapping = {
  [wixCodeLogLevel.INFO]: siteMonitoringSeverity.INFO,
  [wixCodeLogLevel.WARN]: siteMonitoringSeverity.WARNING,
  [wixCodeLogLevel.ERROR]: siteMonitoringSeverity.ERROR,
  [wixCodeLogLevel.LOG]: siteMonitoringSeverity.INFO,
  [wixCodeLogLevel.VERBOSE]: siteMonitoringSeverity.DEBUG
}

const convertToSiteMonitoringSeverity = logLevel => mapping[logLevel]

module.exports.wixCodeLogLevel = wixCodeLogLevel
module.exports.siteMonitoringSeverity = siteMonitoringSeverity
module.exports.convertToSiteMonitoringSeverity = convertToSiteMonitoringSeverity
